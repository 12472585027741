import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useCasinoTopStore } from 'web/src/modules/casino/submodules/top/useCasinoTopStore';
import { usePromotionsTopStore } from 'web/src/modules/promotions/submodules/top/usePromotionsTopStore';
import { useSportlineTopStore } from 'web/src/modules/sportline/submodules/top/store';

interface UseCasinoHomeComposable {
  isReady: Ref<boolean>;
  initialRequests(): Promise<void>;
  syncBackgroundRequests(): Promise<void>;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
}

export function useCasinoHome(): UseCasinoHomeComposable {
  const sportlineTopStore = useSportlineTopStore();
  const isSportlineTopReady = toRef(sportlineTopStore, 'isReady');

  const promotionsTopStore = usePromotionsTopStore();
  const isPromotionsTopReady = toRef(promotionsTopStore, 'isReady');

  const casinoTopStore = useCasinoTopStore();
  const isCasinoTopReady = toRef(casinoTopStore, 'isReady');

  const isReady = computed<boolean>(() => (
    isSportlineTopReady.value
    && isPromotionsTopReady.value
    && isCasinoTopReady.value
  ));

  async function initialRequests(): Promise<void> {
    await Promise.allSettled([
      sportlineTopStore.initialRequests(),
      promotionsTopStore.initialRequests(),
      casinoTopStore.updateJackpots(),
    ]);
  }

  async function syncBackgroundRequests(): Promise<void> {
    await Promise.allSettled([
      sportlineTopStore.syncBackgroundRequests({ silent: true }),
      promotionsTopStore.syncBackgroundRequests(),
    ]);
  }

  async function setBackgroundUpdateEnabled(value: boolean): Promise<void> {
    await Promise.allSettled([
      sportlineTopStore.setBackgroundUpdateEnabled(value),
    ]);
  }

  return {
    isReady,
    initialRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  };
}
